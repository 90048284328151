
import { OrderlinePreflightErrorCodes } from '@/ConstValues';
import { OrderlinePreflightCheck } from '@/helpers/OrderlineHelper';
import { AddressService } from '@/services/address-service';
import { OrderlineDetails } from '@/store/models/OrderlineModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import AddEditBillingAddress from './addressComponents/AddEditBillingAddress.vue';
import AddEditShippingAddress from './AddEditShippingAddress.vue';
import { Confirm } from '@/helpers/AuthHelper';
@Component({
  components: {
    AddEditBillingAddress,
    AddEditShippingAddress
  }
})
export default class OrderlinePreflight extends Vue {
  $refs!: {
    AddEditBillingAddress: HTMLFormElement;
    AddEditShippingAddress: HTMLFormElement;
  };
  /* Properties */
  @Prop() orderlines!: OrderlineDetails[];
  /* Store Actions */
  @Action('getCurrentCustomer', { namespace: 'customer' })
  getCurrentCustomer: () => Promise<void>;
  /* Watchers */
  /* Data */
  actionAfterPrecheck: () => {} | void = () => {};
  currentOrderline: OrderlineDetails = new OrderlineDetails();
  /* Computed */
  /* Async Functions */
  /* Utility Functions */
  async runPreflightCheck(action?: any) {
    if (action) this.actionAfterPrecheck = action;
    for (let ol of this.orderlines) {
      let errorCodes = await OrderlinePreflightCheck(ol);
      if (errorCodes.length > 0) {
        this.currentOrderline = ol;
        this.handleError(errorCodes[0]);
        return;
      }
    }
    if (this.orderlines.some(ol => !ol.Item.IsApproved)) {
      Confirm(
        () => {
          this.actionAfterPrecheck();
        },
        'Are you sure?',
        "One or more of your items is on hold! We won't be able to start on those items until they are approved. Would you still like to place your order? (You will be able to approve them after the order has been placed)",
        'Cancel',
        'I am sure'
      );
    } else {
      this.actionAfterPrecheck();
    }
  }
  async handleError(errorCode: OrderlinePreflightErrorCodes) {
    switch (errorCode) {
      case OrderlinePreflightErrorCodes.MissingBillingAddress: {
        this.$refs.AddEditBillingAddress.openAddressDialog();
        return;
      }
      case OrderlinePreflightErrorCodes.ShippingAddressNotComplete: {
        await this.setOrderlineShippingAddress();
        this.$refs.AddEditShippingAddress.openAddressDialog(
          false,
          "We're missing some things on your address. Please fill out the required fields before placing your order"
        );
      }
    }
  }
  async onBillingAddressAdded() {
    await this.getCurrentCustomer();
    this.runPreflightCheck();
  }
  async onShippingAddressChange() {
    await this.setOrderlineShippingAddress();
    this.runPreflightCheck();
  }
  async setOrderlineShippingAddress() {
    const { data } = await AddressService.GetShippingAddressesFull();
    this.currentOrderline.Address = data.find(
      d => d.Id == this.currentOrderline.Address.Id
    );
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
